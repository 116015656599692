<template>
  <div>
    <b-row>
      <b-col md="6" class="mb-2">
        <b-input-group>
          <b-form-input
            v-model="localSearch"
            v-on:keyup="loadSuggestions"
            ref="inputSearch"
          />
          <span
            v-if="hasSearch"
            v-on:click="limparProcura"
            class="fa fa-close clear-input-search"
          ></span>
          <b-input-group-append>
            <b-button variant="primary" v-on:click="searchText">
              <b-spinner v-if="loadingSuggestions" small></b-spinner>
              <i v-else class="fa fa-search"></i>
            </b-button>
          </b-input-group-append>
        </b-input-group>
        <b-row>
          <b-col md="12">
            <b-list-group
              v-if="showSuggestion && suggestions.length > 0"
              class="suggestion"
            >
              <b-list-group-item
                v-for="(item, index) in suggestions"
                :key="index"
                class="suggestion-item"
                @click="useSuggestion(item)"
              >
                {{ item }}
              </b-list-group-item>
            </b-list-group>
          </b-col>
        </b-row>
      </b-col>
      <br />
      <br />
      <b-col md="3" class="mb-2">
        <slot :name="'segundaColuna'"></slot>
      </b-col>
      <b-col md="3">
        <slot :name="'terceiraColuna'"></slot>
      </b-col>
    </b-row>
    <b-row class="mb-3 ml-1">
      <b-col class="mt-2 pl-0 pr-0" md="4">
        <b-form-checkbox
          id="produtos-favoritos"
          name="produtos-favoritos"
          v-model="bo_produtos_favoritos"
          unchecked-value="false"
          @change="filtarProdutosFavoritos()"
        >
          <span
            ><b>Produtos Favoritos </b>
            <i
              class="fa fa-question-circle"
              title="Selecione os itens clicando na estrela e utilize esse recurso para filtrar somente os itens que você definir como favoritos. Para retirar da lista, basta desmarcar o item clicando novamente na estrela."
            ></i>
          </span>
        </b-form-checkbox>
      </b-col>
      <b-col class="mt-2 pl-0 pr-0" md="4" id="remover-filtro">
        <b-button
          class="btn btn-block btn-ghost-danger"
          @click="limparTodosFiltros()"
        >
          <i class="fa fa-times"></i>
          Limpar todos os filtros
        </b-button>
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col md="2">
        <h6>Linhas / Categorias:</h6>
        <b-list-group
          ref="linhasCategoriasAccordion"
          class="accordion-sub-menu"
        >
          <b-list-group>
            <b-list-group-item
              v-if="filtrouCategoria"
              v-on:click="limparCategorias()"
              button
              class="d-flex justify-content-between align-items-center"
            >
              Limpar Filtros
              <i class="fa fa-close"></i>
            </b-list-group-item>
          </b-list-group>
          <template v-for="item in this.linhas">
            <b-list-group-item
              button
              ref="linhaRef"
              :id="addPrefix(item.linha_codigo, 'id_')"
              :active="linhaAtiva == addPrefix(item.linha_codigo, 'id_')"
              v-on:click="activate"
              v-b-toggle="addPrefix(item.linha_codigo, 'accordion_')"
              class="d-flex justify-content-between align-items-center"
              role="tab"
              :key="addPrefix(item.linha_codigo, 'k')"
            >
              {{ item.linha }}
              <div>
                <i
                  v-if="linhaAtiva == addPrefix(item.linha_codigo, 'id_')"
                  class="fa fa-angle-up"
                ></i>
                <i v-else class="fa fa-angle-down"></i>
              </div>
            </b-list-group-item>
            <b-collapse
              ref="linhaItensRef"
              :id="addPrefix(item.linha_codigo, 'accordion_')"
              accordion="my-accordion"
              role="tabpanel"
              :key="addPrefix(item.linha_codigo, 'l')"
            >
              <b-list-group>
                <b-list-group-item
                  button
                  v-if="item.categorias && item.categorias.length > 1"
                  v-on:click="
                    onLinhaCategoriaClick(
                      getCategoriaCodigos(item.categorias),
                      $event
                    )
                  "
                  :id="
                    addPrefix(item.linha_codigo.toString() + '_todos', 'id_l')
                  "
                  :active="
                    categoriaAtiva ==
                    addPrefix(item.linha_codigo.toString() + '_todos', 'id_l')
                  "
                  class="d-flex align-items-center accordion-sub-item"
                >
                  <i class="fa fa-angle-right mr-1"></i>
                  Todos
                </b-list-group-item>
                <template v-for="categoria in item.categorias">
                  <b-list-group-item
                    button
                    v-on:click="
                      onLinhaCategoriaClick(categoria.categoria_codigo, $event)
                    "
                    :id="
                      addPrefix(
                        item.linha_codigo.toString() +
                          '_c' +
                          categoria.categoria_codigo,
                        'id_l'
                      )
                    "
                    :active="
                      categoriaAtiva ==
                      addPrefix(
                        item.linha_codigo.toString() +
                          '_c' +
                          categoria.categoria_codigo,
                        'id_l'
                      )
                    "
                    class="d-flex align-items-center accordion-sub-item"
                    :key="
                      addPrefix(
                        item.linha_codigo.toString() +
                          '_c' +
                          categoria.categoria_codigo,
                        'l'
                      )
                    "
                    v-b-toggle="`cat-${categoria.categoria_codigo}`"
                  >
                    <i class="fa fa-angle-right mr-1"></i>
                    {{ categoria.categoria }}
                  </b-list-group-item>
                  <b-collapse
                    :id="`cat-${categoria.categoria_codigo}`"
                    :key="`cat-${categoria.categoria_codigo}`"
                    accordion="my-accordion1"
                    role="tabpanel"
                  >
                    <b-list-group>
                      <b-list-group-item
                        button
                        variant="primary"
                        v-for="sub in subcategorias"
                        :key="sub.codigo"
                        :active="subcategoriaAtiva == sub.codigo"
                        v-on:click="onSubcategoriaClick(sub.codigo)"
                      >
                        {{ sub.titulo }}
                      </b-list-group-item>
                    </b-list-group>
                  </b-collapse>
                </template>
              </b-list-group>
            </b-collapse>
          </template>
        </b-list-group>

        <h6>Marcas:</h6>
        <b-list-group ref="marcasAccordion" class="accordion-sub-menu">
          <b-list-group>
            <b-list-group-item
              v-if="filtrouMarca"
              v-on:click="limparMarcas()"
              button
              class="d-flex justify-content-between align-items-center"
            >
              Limpar Filtros
              <i class="fa fa-close"></i>
            </b-list-group-item>
          </b-list-group>
          <template v-for="item in this.marcas">
            <b-list-group-item
              button
              ref="marcaRef"
              :id="addPrefix(item.marca_codigo, 'id_m')"
              :active="marcaAtiva == addPrefix(item.marca_codigo, 'id_m')"
              v-on:click="activateBrand(item.marca_codigo, $event)"
              class="d-flex justify-content-between align-items-center"
              role="tab"
              :key="addPrefix(item.marca_codigo, 'km')"
              >{{ item.marca }}</b-list-group-item
            >
          </template>
        </b-list-group>
      </b-col>

      <b-col md="10">
        <b-row>
          <b-col>
            <h6 v-if="this.searchResult.totalResults > 0">
              {{ this.searchResult.totalResults }} resultados.
            </h6>
            <h6 v-else-if="this.searchResult.totalResults == 0">
              Nenhum resultado encontrado.
            </h6>
          </b-col>
        </b-row>
        <b-row v-if="results.length > 0">
          <template v-for="row in results">
            <div class="five-columns d-flex" :key="row.codigo">
              <product-item
                :codigo="row.codigo"
                :multiplo="row.multiplo"
                :titulo="row.titulo"
                :tipo_origem_produto="row.tipo_origem_produto"
                :url_foto="row.url_foto"
                :cliente_codigo="cliente_codigo"
                :valor_unitario="row.valor_unitario"
                :estoques="row.estoques"
                v-on:onAddMga="onAddMga"
                v-on:onAddItj="onAddItj"
                v-on:onAdd="onAdd"
                v-on:onHideProduct="onHideProductInList"
                :cd_filial="cd_filial"
                :valida_estoque="row.bo_vender_sem_estoque"
                :favorito="row.favorito"
                v-on:onFavoritado="onFavoritado"
              >
                <template v-slot:addButton="slotProps">
                  <slot :name="'addButton'" v-bind:data="slotProps.data"></slot>
                </template>
              </product-item>
            </div>
          </template>
        </b-row>
        <div v-if="loadingProducts" class="text-center">
          <b-spinner
            variant="secondary"
            class="mt-3"
            label="Buscando produtos..."
          ></b-spinner>
        </div>
      </b-col>
    </b-row>
    <go-to-top></go-to-top>
  </div>
</template>

<style>
.suggestion {
  background: #fff;
  padding: 2px;
  border: 1px solid #1985ac;
  border-radius: 4px;
  position: absolute;
  top: 0;
  z-index: 2;
  margin-top: 1px;
}

.suggestion-item {
  border: none;
  border-bottom: 1px solid #efefef !important;
  padding: 10px 20px !important;
  cursor: pointer;
  margin-bottom: 0;
  font-size: 14px !important;
  /* top: 162px !important; */
}

.suggestion-item:last-child {
  border-bottom: 0 !important;
}

.suggestion-item:hover {
  text-decoration: none;
  background-color: #f0f3f5;
}

.hideDropDownButton {
  width: 0px !important;
  padding: 0px !important;
  cursor: none !important;
}

.hideDropDownButton > button {
  width: 0px !important;
  padding: 0px !important;
  cursor: none !important;
}
</style>

<style scoped>
.accordion-sub-item {
  color: #007bff;
  padding-left: 1.5rem !important;
}

.active {
  color: white !important;
}

.list-group-item {
  font-size: small;
  padding: 0.2rem 0.6rem;
}

.accordion-sub-menu {
  margin-bottom: 2rem;
}

@media (min-width: 768px) {
  .five-columns {
    width: 17%;
    margin-left: 1.35%;
    margin-right: 1.35%;
  }
}

@media (max-width: 767px) {
  .five-columns {
    width: 100%;
    margin-left: 1.35%;
    margin-right: 1.35%;
    min-height: 20rem;
  }
}

.clear-input-search {
  margin-right: 0.6rem;
  margin-left: -1.2rem;
  margin-top: 0.6rem;
  position: relative;
  z-index: 2;
  cursor: pointer;
}

#remover-filtro button {
  text-align: left;
  padding-top: 1px;
  padding-bottom: 1px;
  font-size: 14px;
  width: auto;
}
</style>

<script>
import ProductItem from "./ProductItem";
import GoToTop from "@/components/ui/GoToTop.vue";
import Produto from "@/services/produto";
import Linha from "@/services/linha";
import Marca from "@/services/marca";

export default {
  name: "ProductList",
  components: { ProductItem, GoToTop },
  props: {
    id: {
      type: String,
      default: "ProductList",
    },
    resultsPerPage: {
      type: Number,
      default: 10,
    },
    cliente_codigo: {
      type: Number,
      default: 0,
    },
    cd_filial: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      myTimeOut: null,
      suggestions: [],
      bottom: false,
      filtrouCategoria: false,
      filtrouMarca: false,
      loadingProducts: false,
      loadingSuggestions: false,
      showSuggestion: false,
      linhas: new Object(),
      marcas: new Object(),
      linhaAtiva: null,
      marcaAtiva: null,
      subcategorias: [],
      categoriaAtiva: null,
      subcategoriaAtiva: null,
      localSortDirection: "",
      selectedValue: this.resultsPerPage.toString(),
      localSearch:
        this.search && this.search.content ? this.search.content : null,
      localCategorias:
        this.search && this.search.categoria_codigo
          ? this.search.categoria_codigo
          : null,
      localMarcas:
        this.search && this.search.marca_codigo
          ? this.search.marca_codigo
          : null,
      localSubcategorias:
        this.search && this.search.subcategoria_codigo
          ? this.search.subcategoria_codigo
          : null,
      localFilial:
        this.search && this.search.codigo_filial
          ? this.search.codigo_filial
          : null,
      currentPage: this.page,
      searchData: {
        params: {
          search: new Object(),
          page: 1,
          resultsPerPage: this.resultsPerPage,
          sortColumn: null,
          sortDirection: null,
          cd_filial: this.cd_filial,
          cliente_codigo: this.cliente_codigo,
        },
      },
      searchResult: new Object(),
      results: [],
      bo_produtos_favoritos: false,
    };
  },
  computed: {
    hasSearch() {
      if (this.localSearch == null) return false;
      return this.localSearch.trim() != "";
    },
    currentResults() {
      if (
        this.searchResult.resultsPerPage * this.searchResult.page >
        this.searchResult.totalResults
      )
        return this.searchResult.totalResults;
      else return this.searchResult.resultsPerPage * this.searchResult.page;
    },
  },
  beforeMount() {
    this.loadLines();
    this.loadBrands();
  },
  beforeDestroy() {
    document.removeEventListener("click", this.verificarCliqueFora);
  },
  mounted() {
    document.addEventListener("click", this.verificarCliqueFora);
  },
  watch: {
    bottom(bottom) {
      if (bottom) {
        this.loadProducts(this.searchData);
      }
    },
  },
  created() {
    window.addEventListener("scroll", () => {
      this.bottom = this.bottomVisible();
    });
    this.loadProducts(this.searchData);
  },
  methods: {
    verificarCliqueFora(event) {
      if (
        this.$refs.inputSearch &&
        !this.$refs.inputSearch.$el.contains(event.target)
      ) {
        this.showSuggestion = false;
      } else {
        this.showSuggestion = true;
      }
    },
    loadProducts(data = null) {
      if (
        this.loadingProducts == true ||
        this.searchData.params.page * 1 > this.searchResult.totalPages * 1
      ) {
        return;
      }

      this.loadingProducts = true;
      this.searchResult.totalResults = -1;
      if (
        data.params.cliente_codigo == null ||
        data.params.cliente_codigo == "0" ||
        this.cliente_codigo == "0" ||
        this.cliente_codigo == null
      ) {
        let codigoCarrinho =
          this.$store.state.carrinhoitj.cliente_codigo == null
            ? this.$store.state.carrinho.cliente_codigo
            : this.$store.state.carrinhoitj.cliente_codigo;
        data.params.cliente_codigo = codigoCarrinho;
      } else {
        data.params.cliente_codigo = this.cliente_codigo;
      }
      data.params.produtos_favoritos = this.bo_produtos_favoritos;

      Produto.getListaProdutos(data)
        .then((response) => {
          this.searchResult = response.data.data;
          if (
            this.searchData.params.page * 1 <=
            this.searchResult.totalPages * 1
          ) {
            this.searchData.params.page++;
          }
          if (this.searchResult.results) {
            for (var key in this.searchResult.results) {
              this.results.push(this.searchResult.results[key]);
            }
          }
        })
        .catch((e) => {
          if (this.bo_produtos_favoritos)
            this.$helper.showMsg(
              "Não foram encontrados produtos favoritos. Para exibir todos produtos, desmarque o filtro 'Produtos Favoritos'",
              "warning"
            );
          else this.$helper.showErrorResponse(e);

          if (
            e.response &&
            e.response.status &&
            e.response.status === 404 &&
            e.response.data
          ) {
            this.results = [];
            this.limparProcura();
          }
        })
        .finally(() => {
          this.loadingProducts = false;
          this.bottom = true;
        });
    },
    filtarProdutosFavoritos() {
      this.results = [];
      this.searchData.params.search = this.createSearchParams();
      this.searchData.params.page = 1;
      this.searchData.params.sortColumn = "";
      this.searchData.params.sortDirection = "asc";
      this.searchData.params.resultsPerPage = this.selectedValue * 1;
      this.loadProducts(this.searchData);
    },
    loadSuggestions(event) {
      this.suggestions = [];

      const keyPressed = event.key;

      if (keyPressed === "Enter") {
        this.searchText();
      } else {
        clearTimeout(this.myTimeOut);
        var self = this;
        this.myTimeOut = setTimeout(function () {
          if (self.localSearch == null) return;
          if (self.localSearch.length <= 2) return;
          self.loadingSuggestions = true;
          Produto.getSearchSuggestions(self.localSearch)
            .then((response) => {
              self.suggestions = response.data.data;
            })
            .catch((e) => {
              console.log(e);
            })
            .finally(() => {
              self.loadingSuggestions = false;
              self.$refs.inputSearch.focus();
              self.showSuggestion = true;
            });
        }, 500);
      }
    },
    addPrefix(item, prefix) {
      return prefix + item.toString();
    },
    loadLines() {
      Linha.getLinhasComCategorias()
        .then((response) => {
          this.linhas = response.data.data;
        })
        .catch((e) => {
          this.$helper.showErrorResponse(e);
        });
    },
    loadBrands() {
      Marca.getMarcasComProdutos()
        .then((response) => {
          this.marcas = response.data.data;
        })
        .catch((e) => {
          this.$helper.showErrorResponse(e);
        });
    },
    createSearchParams() {
      let search = new Object();
      if (this.localCategorias != null) {
        search.categoria_codigo = this.localCategorias;
      }
      if (this.localSubcategorias != null) {
        search.subcategoria_codigo = this.localSubcategorias;
      }
      if (this.localMarcas != null) {
        search.marca_codigo = this.localMarcas;
      }
      if (this.localSearch != null) {
        search.content = this.localSearch;
      }
      if (this.localFilial != null) {
        seach.codigo_filial = this.cd_filial;
      }
      return search;
    },
    searchText() {
      if (this.localSearch == null || this.localSearch.trim() == "") {
        return;
      }
      this.results = [];
      this.showSuggestion = false;
      this.searchData.params.search = this.createSearchParams();
      this.searchData.params.page = 1;
      this.searchData.params.sortColumn = "";
      this.searchData.params.sortDirection = "asc";
      this.searchData.params.resultsPerPage = this.selectedValue * 1;
      this.loadProducts(this.searchData);
    },
    showSubcategoria(cat_cod) {
      let sub = [];
      let cat = [];
      let i = 0;
      let l = 0;
      let s = 0;
      let cat_existe = "";
      for (i = 0; i < this.linhas.length; i++) {
        cat = this.linhas[i].categorias;
        for (l = 0; l < cat.length; l++) {
          if (cat[l].categoria_codigo == cat_cod) {
            cat_existe = Object.keys(cat[l]);
            if (cat_existe[2] == "subcategorias") {
              this.subcategorias = cat[l].subcategorias;
            }
          }
        }
      }
    },
    onLinhaCategoriaClick(categorias_codigo, event) {
      this.results = [];
      this.subcategorias = [];
      this.categoriaAtiva = event.currentTarget.id;
      this.localCategorias = categorias_codigo;
      this.localSubcategorias = null;
      this.filtrouCategoria = true;
      this.searchData.params.search = this.createSearchParams();
      this.searchData.params.page = 1;
      this.searchData.params.sortColumn = "";
      this.searchData.params.sortDirection = "asc";
      this.searchData.params.resultsPerPage = this.selectedValue * 1;
      this.showSubcategoria(categorias_codigo);
      this.loadProducts(this.searchData);
    },
    onSubcategoriaClick(cod_sub) {
      this.results = [];
      this.localSubcategorias = cod_sub;
      this.searchData.params.search = this.createSearchParams();
      this.searchData.params.page = 1;
      this.searchData.params.sortColumn = "";
      this.searchData.params.sortDirection = "asc";
      this.searchData.params.resultsPerPage = this.selectedValue * 1;
      this.loadProducts(this.searchData);
    },
    onResultsPerPageChange() {
      this.results = [];
      this.searchData.params.search = this.createSearchParams();
      this.searchData.params.page = 1;
      this.searchData.params.sortColumn = this.sortColumn;
      this.searchData.params.sortDirection = this.sortDirection;
      this.searchData.params.resultsPerPage = this.selectedValue * 1;
      this.loadProducts(this.searchData);
    },
    sort(column) {
      this.results = [];
      this.searchData.params.search = this.createSearchParams();
      this.searchData.params.page = this.page;
      this.searchData.params.sortColumn = column;
      this.searchData.params.sortDirection = this.getSortDirection();
      this.searchData.params.resultsPerPage = this.selectedValue * 1;
      this.loadProducts(this.searchData);
    },
    resetSearch() {
      this.results = [];
      this.searchData.params.search = this.createSearchParams();
      this.searchData.params.page = 1;
      this.searchData.params.sortColumn = "";
      this.searchData.params.sortDirection = "asc";
      this.searchData.params.resultsPerPage = this.selectedValue * 1;
      this.loadProducts(this.searchData);
    },
    getSortDirection() {
      if (this.localSortDirection === "") {
        this.localSortDirection = "asc";
      } else if (this.$props.sortDirection === "asc") {
        this.localSortDirection = "desc";
      } else {
        this.localSortDirection = "asc";
      }
      return this.localSortDirection;
    },
    getCategoriaCodigos(categorias) {
      return categorias.map((categoria) => categoria.categoria_codigo);
    },
    limparFiltroProcura() {
      this.localSearch = "";
      this.bo_produtos_favoritos = false;
    },
    limparProcura() {
      this.showSuggestion = false;
      this.limparFiltroProcura();
      this.resetSearch();
    },
    limparCategorias() {
      this.limparFiltrosCategoria();
      this.resetSearch();
    },
    limparFiltrosCategoria() {
      this.filtrouCategoria = false;
      this.linhaAtiva = null;
      this.categoriaAtiva = null;
      this.subcategoriaAtiva = null;
      this.$refs.linhaItensRef.map((c) => (c.show = false));
      this.localCategorias = null;
      this.localSubcategorias = null;
    },
    limparMarcas() {
      this.limparFiltrosMarcas();
      this.resetSearch();
    },
    limparFiltrosMarcas() {
      this.filtrouMarca = false;
      this.marcaAtiva = null;
      this.localMarcas = null;
    },
    limparTodosFiltros() {
      this.limparFiltrosCategoria();
      this.limparFiltrosMarcas();
      this.limparFiltroProcura();
      this.resetSearch();
    },
    onAddMga(data) {
      this.$emit("onAddMga", data);
    },
    onAddItj(data) {
      this.$emit("onAddItj", data);
    },
    onAdd() {
      this.$emit("onAdd", false);
    },
    onHideProductInList(codigoProduto) {
      let index = this.results.findIndex((el) => el.codigo == codigoProduto);
      if (index > -1) {
        this.loadProducts(this.results.splice(index, 1));
      }
    },
    activate(event) {
      this.linhaAtiva = event.currentTarget.id;
      this.localCategorias = null;
      this.localSubcategorias = null;
    },
    activateBrand(marcas_codigo, event) {
      this.results = [];
      this.marcaAtiva = event.currentTarget.id;
      this.localMarcas = marcas_codigo;
      this.filtrouMarca = true;
      this.searchData.params.search = this.createSearchParams();
      this.searchData.params.page = 1;
      this.searchData.params.sortColumn = "";
      this.searchData.params.sortDirection = "asc";
      this.searchData.params.resultsPerPage = this.selectedValue * 1;
      this.loadProducts(this.searchData);
    },
    bottomVisible() {
      const scrollY = window.scrollY + 240;
      const visible = document.documentElement.clientHeight;
      const pageHeight = document.documentElement.scrollHeight;
      const bottomOfPage = visible + scrollY >= pageHeight;
      return bottomOfPage || pageHeight < visible;
    },
    useSuggestion(suggestion) {
      this.localSearch = suggestion;
      this.searchText();
      this.showSuggestion = false;
    },
    onFavoritado(codigoProduto, favorito) {
      let index = this.results.findIndex((el) => el.codigo == codigoProduto);
      if (index > -1) {
        this.results[index].favorito = favorito;
      }
    },
  },
};
</script>
